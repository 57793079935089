import PropTypes from 'prop-types';

import { sendText, notFindNumberText } from 'assets/constants';

import SimpleField from 'components/FormControl/inputField/_field';
import Button from 'components/Button';
import MiniForm from '../miniForm';
import Loader from 'components/Loader';

const AdditionalField = ({ register, errors }) => (
	<SimpleField
		id='student-id'
		type='text'
		labelText='Student ID'
		placeholder='000000000'
		errorMassage={notFindNumberText}
		register={register('StudentsId', { required: true })}
		isError={errors?.StudentsId}
	/>
);

export const ButtonBlock = ({ isLoader }) => (
	<Button isRed disabled={isLoader}>
		{isLoader ? <Loader /> : sendText}
	</Button>
);

const ForStudentsForm = ({ className }) => (
	<MiniForm
		className={className}
		additionalField={(register, errors) => (
			<AdditionalField register={register} errors={errors} />
		)}
		buttonBlock={(isLoading) => <ButtonBlock isLoader={isLoading} />}
		massage='forStudent'
		isZoomModal
		requestUrl='student/get-license'
		isStudentForm
	/>
);

ForStudentsForm.propTypes = {
	className: PropTypes.object,
};

export default ForStudentsForm;
