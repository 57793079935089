import PropTypes from "prop-types";
import { block } from "bem-cn";

const cn = block("main-paragraph");

const Paragraph = ({ children, className }) => (
  <p className={cn("wrap").mix(className)}>{children}</p>
);

Paragraph.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.object,
};

export default Paragraph;
