import { graphql } from "gatsby";
import { block } from "bem-cn";

import Layout from "layouts/";
import Heading from "components/Heading";
import Container from "components/Container";
import Paragraph from "components/Paragraph";
import ForStudentsForm from "components/Forms/forStudents";

import "./style.scss";

const cn = block("students-page");

const StudentsPage = ({ data }) => {
  const { seo, title, bannerDescription } = data.markdownRemark.frontmatter;

  return (
    <Layout seo={seo}>
      <Container className={cn("wrap")}>
        <Heading color="general" weight="bold" className={cn("title")}>
          {title}
        </Heading>
        <div className={cn("content")}>
          {bannerDescription.map((item) => (
            <Paragraph key={item}>{item}</Paragraph>
          ))}
          <ForStudentsForm className={cn("form", { wrap: true })} />
        </div>
      </Container>
    </Layout>
  );
};

export default StudentsPage;

export const query = graphql`
  query getStudentsPageData {
    markdownRemark(frontmatter: { slug: { eq: "/students" } }) {
      frontmatter {
        title
        bannerDescription
        seo {
          description
          image
          link
          title
        }
      }
    }
  }
`;
